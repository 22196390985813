const initialState = {
  editingChargingRate: {},
};

const chargingRate = (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_CHARGING_RATE":
      return {
        ...state,
        editingChargingRate: action.data,
      };

    default:
      return state;
  }
};
export default chargingRate;
