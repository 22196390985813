const initialState = {
  editingChargePoint: {},
  editingChargePointConnector: {},
  selectedItem: null,
};

const chargePoint = (state = initialState, action) => {
  switch (action.type) {
    case 'EDIT_CHARGE_POINT':
      return {
        ...state,
        editingChargePoint: action.data,
      };
    case 'EDIT_CHARGE_POINT_CONNECTOR':
      return {
        ...state,
        editingChargePointConnector: action.data,
      };
    case 'SELECT_CHARGE_POINT':
      return {
        ...state,
        selectedItem: action.data,
      };
    default:
      return state;
  }
};
export default chargePoint;
