

const initialState = {
  editingApplication: {},
};

const editApplication = (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_APPLICATION":
      return {
        ...state,
        editingApplication: action.data,
      };

    default:
      return state;
  }
};
export default editApplication