

const initialState = {
  editingOcppTag: {},
};

const OcppTag = (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_OCPP_TAG":
      return {
        ...state,
        editingOcppTag: action.data,
      };

    default:
      return state;
  }
};
export default OcppTag