const initialState = {
  editingUser:null
}

const editUser = (state = initialState, action) => {
    switch (action.type) {
        case 'EDIT_USER':
            return {
                ...state,
                editingUser: action.user,
              }
            break;
    
        default:
            return state;
            break;
    }
}

export default editUser
