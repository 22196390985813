import React, {useCallback, useEffect, useState} from 'react';

const FallbackImage = props => {
  const {src, fallback, ...rest} = props;
  const [currentSrc, setCurrentSrc] = useState(src);
  // console.log('src', src);
  useEffect(() => {
    setCurrentSrc(src);
  }, [src]);
  const onError = useCallback(() => {
    // console.log('wtf');
    setCurrentSrc(fallback);
  }, [fallback]);
  return <img {...rest} src={currentSrc} onError={onError} />;
};

export default FallbackImage;
