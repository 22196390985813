import React from 'react';
import logo from '../../../assets/img/svg/chargesoftMarker.svg';
import '../../../assets/scss/components/app-loader.scss';
import FallbackImage from "../../FallbackImage";
import {ASSET_BASE_URL} from "../../../helpers/Config";
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100">
        <FallbackImage className="fallback-logo" src={`${ASSET_BASE_URL}spinnerLogo.png`} fallback={logo} alt="logo" />
        <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div>
      </div>
    );
  }
}

export default SpinnerComponent;
