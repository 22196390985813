import {
  getRequestWithToken,
  postRequestWithToken,
  deleteRequestWithToken,
  putRequestWithToken,
} from '../helpers/request';

export const getProfile = params => {
  const apiUrl = `profile/`;

  return getRequestWithToken(apiUrl);
};

export const editProfile = params => {
  const apiUrl = `profile`;
  return postRequestWithToken(apiUrl, params);
};
