

const initialState = {
  editingStation: null,
};

const editProducts = (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_PRODUCTS":
      return {
        ...state,
        editingStation: action.station,
      };

    default:
      return state;
  }
};
export default editProducts