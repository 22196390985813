import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Layout} from './utility/context/Layout';
import * as serviceWorker from './serviceWorker';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './redux/storeConfig/store';
import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import './index.scss';
import './assets/@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toggle/style.css';
import ReloadThemeConfig from './helpers/ReloadThemeConfig';
const LazyApp = lazy(() => import('./App'));

ReloadThemeConfig();

// configureDatabase()
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        {/* <Spinner /> */}
        <Layout>
          <LazyApp />
        </Layout>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
