const initialState = {
  editingTariff: {},
};

const Tariff = (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_TARIFF":
      return {
        ...state,
        editingTariff: action.data,
      };

    default:
      return state;
  }
};
export default Tariff;
