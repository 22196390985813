import { combineReducers } from "redux";

const initialState = {
  editingStation: null,
};

const editStation = (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_STATION":
      return {
        ...state,
        editingStation: action.station,
      };

    default:
      return state;
  }
};

const getPublicBrand = (state={brand:null} , action)=>{
  switch (action.type) {
    case "CHOOSE_PUBLIC_STATION_BRAND":
      return {
        ...state,
        brand: action.brand,
      };

    default:
      return state;
  }
}


const bluedotStations= combineReducers({editStation:editStation , getPublicBrand:getPublicBrand});
export default bluedotStations;