import {combineReducers} from 'redux';
import customizer from './customizer/';
import auth from './auth/';
import users from './users/users';
import stations from './stations/stations';
import bluedotStations from './Individualstations/Individualstations';
import products from './products/products';
import domain from './domain/domain';
import ocppTag from './ocppTag/ocppTag';
import application from './application/application';
import driver from './driver/driver';
import location from './location/location';
import tariff from './tariff/tariff';
import toastReducer from './toastReducer/toastReducer';
import chargingRate from './chargingRate/chargingRate';
import chargePoint from './chargePoint/chargePoint';
import transactions from './transactions/transactions';
import vehicles from './vehicles/vehicles';
import profile from './profile/profile';
import employee from './employee/employee';
const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  users: users,
  stations: stations,
  bluedotStations: bluedotStations,
  products: products,
  toast: toastReducer,
  domain,
  application,
  ocppTag,
  driver,
  location,
  tariff,
  chargingRate,
  chargePoint,
  transactions,
  vehicles,
  profile,
  employee,
});

export default rootReducer;
