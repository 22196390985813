const initialState = {
  selectedItem: null,
  editingTransactions: {},
};

const transactions = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_TRANSACTIONS':
      return {
        ...state,
        selectedItem: action.data,
      };
    case 'EDIT_TRANSACTIONS':
      return {
        ...state,
        editingTransactions: action.data,
      };
    default:
      return state;
  }
};
export default transactions;
