import {SERVER_URL} from '../../../helpers/Config';
import {history} from '../../../history';
import React from 'react';
import {setToken, removeToken, setRefreshToken} from '../../../helpers/Token';
import {toast} from 'react-toastify';
import i18n from '../../../i18next';
import {axios} from '../../../helpers/request';
export const changeRole = role => {
  return dispatch => dispatch({type: 'CHANGE_ROLE', userRole: role});
};

export const loginWithJWT = user => {
  return dispatch => {
    axios
      .post(SERVER_URL + 'login', {
        email: user.email,
        password: user.password,
      })
      .then(response => {
        let loggedInUser;

        if (response.data && response.data.isSuccess) {
          loggedInUser = response.data.data.token.access_token;
          let refresh_token = response.data.data.token.refresh_token;
          setToken(loggedInUser);
          setRefreshToken(refresh_token);
          dispatch({
            type: 'LOGIN_WITH_JWT',
            payload: {loggedInUser, loggedInWith: 'jwt', userRole: 'admin'},
          });
          dispatch({
            type: 'TOAST',
            payload: {text: i18n.t('Welcome to Controlmax'), type: 'success'},
          });
          history.push('/dashboard');
        } else {
          dispatch({
            type: 'TOAST',
            payload: {
              text: i18n.t('Username or password incorrect'),
              type: 'error',
            },
          });
        }
      })
      .catch(err => {
        console.log(err);
        toast.error(i18n.t('Username or password incorrect'));
      });
  };
};

export const logoutWithJWT = () => {
  return dispatch => {
    dispatch({type: 'LOGOUT_WITH_JWT', payload: {}});
    removeToken();
    history.push('/pages/login');
  };
};
