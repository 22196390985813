import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEng from '../src/assets/data/locales/en/translation.json';
import translationTr from '../src/assets/data/locales/tr/translation.json';
//translation
const DETECTION_OPTIONS = {
  order: ['navigator'],
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    //or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    detection: DETECTION_OPTIONS,
    resources: {
      // de: {
      //   translation: translationGer,
      // },
      // fr: {
      //   translation: translationFr,
      // },
      en: {
        translation: translationEng,
      },
      tr: {
        translation: translationTr,
      },
    },
    // lng: "", // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
