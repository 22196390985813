
import { toast } from "react-toastify";

 const toastReducer = (state = { type:null , text:"" }, action) => {
  switch (action.type) {
    case "TOAST": {
      switch (action.payload.type) {
        case "success":
          toast.success(action.payload.text)
          break;
        case "error":
          toast.error(action.payload.text);
          break;
          case "warn":
            toast.warn(action.payload.text);
            break;
        default:
          break;
      }
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}

export default toastReducer
