import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {Disc, X, Circle} from 'react-feather';
import classnames from 'classnames';
import logo from '../../../../assets/img/svg/chargesoftMarker.svg';
import FallbackImage from '../../../../components/FallbackImage';
import {ASSET_BASE_URL} from '../../../../helpers/Config';
import {TENANT_TITLE} from '../../../../helpers/Config';
const styles = {
  logoContainer: {
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
  },
  logo: {
    maxWidth: 100,
    height: 40,
  },
  logoArea: {
    display: 'flex',
    alignSelf: 'center',
    lineHeight: 3,
  },
  logoText: {
    fontSize: '16px',
    paddingTop: '10px',
    paddingLeft: '5px',
    whiteSpace: 'nowrap',
    width: '80%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    border: '1px 000000 #',
  },
};
class SidebarHeader extends Component {
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow,
    } = this.props;
    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto " style={styles.logoArea}>
            <NavLink to="/" style={styles.logoContainer}>
              <FallbackImage
                src={`${ASSET_BASE_URL}logo.png`}
                fallback={logo}
                style={styles.logo}
              />
              {/* <h2 className="brand-text mb-0" style={styles.logoText}>
                {TENANT_TITLE}
              </h2> */}
            </NavLink>
          </li>

          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                <Disc
                  onClick={() => {
                    toggleSidebarMenu(true);
                    toggle();
                  }}
                  className={classnames(
                    'toggle-icon icon-x d-none d-xl-block font-medium-4',
                    {
                      'text-primary': activeTheme === 'primary',
                      'text-success': activeTheme === 'success',
                      'text-danger': activeTheme === 'danger',
                      'text-info': activeTheme === 'info',
                      'text-warning': activeTheme === 'warning',
                      'text-dark': activeTheme === 'dark',
                    },
                  )}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                <Circle
                  onClick={() => {
                    toggleSidebarMenu(false);
                    toggle();
                  }}
                  className={classnames(
                    'toggle-icon icon-x d-none d-xl-block font-medium-4',
                    {
                      'text-primary': activeTheme === 'primary',
                      'text-success': activeTheme === 'success',
                      'text-danger': activeTheme === 'danger',
                      'text-info': activeTheme === 'info',
                      'text-warning': activeTheme === 'warning',
                      'text-dark': activeTheme === 'dark',
                    },
                  )}
                  size={20}
                />
              )}
              <X
                onClick={sidebarVisibility}
                className={classnames(
                  'toggle-icon icon-x d-block d-xl-none font-medium-4',
                  {
                    'text-primary': activeTheme === 'primary',
                    'text-success': activeTheme === 'success',
                    'text-danger': activeTheme === 'danger',
                    'text-info': activeTheme === 'info',
                    'text-warning': activeTheme === 'warning',
                    'text-dark': activeTheme === 'dark',
                  },
                )}
                size={20}
              />
            </div>
          </li>
        </ul>
        <div
          className={classnames('shadow-bottom', {
            'd-none': menuShadow === false,
          })}
        />
      </div>
    );
  }
}

export default SidebarHeader;
