import ax from 'axios';
import {
  SERVER_URL,
  DOMAIN_KEY,
  GENERAL_SERVER_URL,
  OCPP_REST_API_URL,
} from './Config';
import {getRefreshToken, getToken, setRefreshToken, setToken} from './Token';
import {store} from '../redux/storeConfig/store';
import i18n from '../i18next';

import {toast} from 'react-toastify';
export const axios = ax.create({
  headers: {domainKey: DOMAIN_KEY},
});

export const getRequest = url => {
  return axios.get(SERVER_URL + url).then(response => {
    if (response.data.error) {
      throw response.data.error;
    } else {
      return response.data;
    }
  });
};
export const postRequest = (url, params) => {
  return axios.post(SERVER_URL + url, params).then(response => {
    if (response.data.error) {
      throw response.data.error;
    } else {
      return response.data;
    }
  });
};

export const postRequestWithToken = async (url, params) => {
  const token = await getToken();
  return axios({
    method: 'POST',
    url: SERVER_URL + url,
    headers: {Authorization: token},
    data: params,
  }).then(response => {
    if (response.data.error) {
      throw response.data.error;
    } else {
      return response.data;
    }
  });
};
export const postRequestMultipart = async (url, params) => {
  const token = await getToken();
  return axios({
    method: 'PUT',
    url: SERVER_URL + url,
    headers: {Authorization: token, 'Content-Type': 'multipart/form-data'},
    data: params,
  }).then(response => {
    if (response.data.error) {
      throw response.data.error;
    } else {
      return response.data;
    }
  });
};
export const postOCPPRequestWithToken = async (url, params) => {
  const token = await getToken();
  return axios({
    method: 'POST',
    url: OCPP_REST_API_URL + url,
    headers: {Authorization: token},
    data: params,
  }).then(response => {
    if (response.data.error) {
      throw response.data.error;
    } else {
      return response.data.result;
    }
  });
};
export const putRequestWithToken = async (url, params) => {
  const token = await getToken();
  return axios({
    method: 'PUT',
    url: SERVER_URL + url,
    headers: {Authorization: token},
    data: params,
  }).then(response => {
    if (response.data.error) {
      throw response.data.error;
    } else {
      return response.data;
    }
  });
};

export const getRequestWithToken = async (url, params) => {
  // console.log('Serverurl', SERVER_URL);

  const token = await getToken();
  return axios({
    method: 'GET',
    url: SERVER_URL + url,
    headers: {Authorization: token},
    params,
  }).then(response => {
    console.log('error', response);
    if (response === undefined || response.data.error) {
      throw response && response.data ? response.data.error : 'error';
    } else {
      return response.data;
    }
  });
};

export const generalGetRequestWithToken = async (url, params) => {
  console.log('Serverurl', GENERAL_SERVER_URL);

  const token = await getToken();
  return axios({
    method: 'GET',
    url: GENERAL_SERVER_URL + url,
    headers: {Authorization: token},
    params,
  }).then(response => {
    console.log('error', response);
    if (response === undefined || response.data.error) {
      throw response && response.data ? response.data.error : 'error';
    } else {
      return response.data;
    }
  });
};
export const putRequestMultipart = async (url, params) => {
  const token = await getToken();
  return axios({
    method: 'PUT',
    url: SERVER_URL + url,
    headers: {Authorization: token, 'Content-Type': 'multipart/form-data'},
    data: params,
  }).then(response => {
    if (response.data.error) {
      throw response.data.error;
    } else {
      return response.data;
    }
  });
};
export const deleteRequestWithToken = async url => {
  const token = await getToken();
  return axios({
    method: 'DELETE',
    url: SERVER_URL + url,
    headers: {Authorization: token},
  }).then(response => {
    if (response.data.error) {
      throw response.data.error;
    } else {
      return response.data;
    }
  });
};

function InterceptorsRequest(config) {
  config.headers['Content-Type'] = 'application/json';
  // console.log(
  //   'API_REQUEST:\n URL:',
  //   config.url,
  //   '\nAuthorization:',
  //   config.headers.Authorization,
  //   '\nBody:',
  //   JSON.stringify(config.data),
  // );
  return config;
}

const _handleCommonError = errorResponse => {
  // TODO: Handle Error
  if (errorResponse.message) {
  }
};

const _interceptorsResponseError = error => {
  if (error && error.response && error.response.status)
    switch (error.response.status) {
      case 401:
        // removeToken();
        // will redirect to "login" when Unauthorised error will raise
        // navigateFromOutside.navigate('LoginScreen', 'Login');
        const dispatch = store.dispatch;
        dispatch({type: 'LOGOUT_WITH_JWT', payload: {}});
        break;
      default:
        _handleCommonError(error);
        break;
    }
  else {
    alert(error);
  }
};

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    return InterceptorsRequest(config);
  },
  error => {
    console.log('API_REQUEST_ERROR:', error);
    _interceptorsResponseError(error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    // console.log(
    //   "\n\nAPI_RESPONSE:\nStatus:",
    //   // response.status,
    //   "\nData:",
    //   response.data,
    //   "\n\n"
    // );
    return response;
  },
  err => {
    console.log('API_RESPONSE_ERROR', err.response);
    // _interceptorsResponseError(error);
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      if (err?.response?.status === 401 && err.config && !err.config._retry) {
        originalReq._retry = true;

        let res = fetch(SERVER_URL + 'refresh-token', {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json, text/plain, /',
            Authorization: getRefreshToken(),
            domainKey: DOMAIN_KEY,
          },

          redirect: 'follow',
          referrer: 'no-referrer',
        })
          .then(res => res.json())
          .then(res => {
            console.log(res);
            //  this.setSession({token: res.data.access_token});
            // originalReq.headers['Token'] = res.token;
            // originalReq.headers['Device'] = "device";

            originalReq.headers['Authorization'] = res.data.access_token;
            setRefreshToken(res.data.refresh_token);
            return setToken(res.data.access_token);
          })
          .then(res => {
            //  axios.defaults.headers.common['Authorization'] =getToken();
            return axios(originalReq);
          })
          .catch(err => {
            console.log('error', err);
            const dispatch = store.dispatch;
            dispatch({type: 'LOGOUT_WITH_JWT', payload: {}});
            return reject(err);
          });

        resolve(res);
      } else {
        if (err?.response?.status === 403 || err?.response?.status === 409) {
          toast.error(i18n.t('youHaveNoPermission'));
        } else if (err?.response?.status === 404) {
          toast.error(i18n.t('notFound404'));
        } else if (
          err?.response?.status === 500 ||
          err?.response?.status === 400
        ) {
          toast.error(i18n.t('serverErrorPleaseContactSupport'));
        }
        //    if (error && error.response && error.response.status)
        // switch (error.response.status) {
        //   case 401:
        //     // removeToken();
        //     // will redirect to "login" when Unauthorised error will raise
        //     // navigateFromOutside.navigate('LoginScreen', 'Login');
        //     const dispatch = store.dispatch;
        //     dispatch({ type: "LOGOUT_WITH_JWT", payload: {} });
        //     break;
        //   default:
        //     _handleCommonError(error);
        //     break;
        // }
        //   _interceptorsResponseError(err)
      }

      return reject(err);
    });
  },
);

// axios.interceptors.response.use(response => {
//   return response;
// }, err => {
//   return new Promise((resolve, reject) => {
//       const originalReq = err.config;
//       if ( err.response.status === 401 && err.config && !err.config.__isRetryRequest )
//       {
//           originalReq._retry = true;

//           let res = fetch('http://localhost:8080/api/v1/auth/refresh', {
//               method: 'POST',
//               mode: 'cors',
//               cache: 'no-cache',
//               credentials: 'same-origin',
//               headers: {
//                   'Content-Type': 'application/json',
//                   'Device': 'device',
//                   'Token': localStorage.getItem("token")
//               },
//               redirect: 'follow',
//               referrer: 'no-referrer',
//               body: JSON.stringify({
//                   token: localStorage.getItem("token"),
//                   refresh_token: localStorage.getItem("refresh_token")
//               }),
//           }).then(res => res.json()).then(res => {
//               console.log(res);
//               this.setSession({token: res.token, refresh_token: res.refresh});
//               originalReq.headers['Token'] = res.token;
//               originalReq.headers['Device'] = "device";

//               return axios(originalReq);
//           });

//           resolve(res);
//       }

//       return Promise.reject(err);
//   });
// });
