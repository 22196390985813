import { THEME_CONFIG_URL } from './Config';
import { store } from '../redux/storeConfig/store';
import { setThemeConfig } from '../redux/actions/customizer';

window.onThemeConfigReload = (data) => {
  const {
    primary,
    secondary,
    ...themeConfig
  } = data;
  setThemeConfig(themeConfig)(store.dispatch);
  // todo maybe use primary/secondary
}

const ReloadThemeConfig = async () => {
  const script = document.createElement("script");

  script.src = THEME_CONFIG_URL;
  script.async = true;

  document.body.appendChild(script);
}

export default ReloadThemeConfig
