import React from 'react';
import * as Icon from 'react-feather';

const navigationConfig = [
  {
    id: 'home',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/dashboard',
  },
  // {
  //   id: "domains",
  //   title: "Domains",
  //   type: "item",
  //   icon: <Icon.List size={20} />,
  //   permissions: ["admin", "person"],
  //   navLink: "/domains",
  // },
  {
    id: 'applications',
    title: 'Applications',
    type: 'item',
    icon: <Icon.List size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/applications',
  },
  {
    id: 'Locations',
    title: 'Locations',
    type: 'item',
    icon: <Icon.Map size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/locations',
  },
  {
    id: 'CharginRates',
    title: 'Charging Rates',
    type: 'item',
    icon: <Icon.BarChart size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/charging-rates',
  },
  {
    id: 'chargestations',
    title: 'Charge Stations',
    type: 'item',
    icon: <Icon.BatteryCharging size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/chargestations',
  },
  {
    id: 'ocppTags',
    title: 'OCPP Tags',
    type: 'item',
    icon: <Icon.Tag size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/ocpp-tags',
  },
  {
    id: 'employees',
    title: 'Employees',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/employees',
  },
  {
    id: 'transactions',
    title: 'Transactions',
    type: 'item',
    icon: <Icon.FileText size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/transactions',
  },
  {
    id: 'Payments',
    title: 'Payments',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/payments',
  },
  {
    id: 'drivers',
    title: 'Drivers',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ['admin', 'person'],
    navLink: '/drivers',
  },
];

export default navigationConfig;
